import React, { useEffect, useRef } from 'react'
import classNames from 'classnames/bind'
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { linkResolver } from 'utils/linkResolver'

import Label from './Label'
import { MenuText } from 'components/ui/Text'

import * as s from './Header.module.scss'
const cn = classNames.bind(s)

const MenuLink = ({ document, label }: { document: { type: string; uid: string }; label: string }) => {
  const linkRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (!linkRef.current) return
    //@ts-ignore
    linkRef.current.classList.toggle(cn('active'), location.pathname === linkResolver(document))
  }, [linkRef, location])

  return (
    <MenuText className={cn('text')}>
      <Link to={linkResolver(document)} className={cn('link')} ref={linkRef}>
        <Label label={label} />
      </Link>
    </MenuText>
  )
}

export default MenuLink
