import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { m } from 'framer-motion'

import MenuLink from './MenuLink'

const linksQuery = graphql`
  {
    prismicNavigation {
      data {
        main_menu {
          menu_link {
            document {
              ... on PrismicPage {
                uid
                type
              }
              ... on PrismicHomepage {
                uid
                type
              }
            }
          }
          menu_label
        }
      }
    }
  }
`

const itemVariants = {
  hidden: {
    y: '10%',
    opacity: 0,
  },
  show: {
    y: '0%',
    opacity: 1,
    transition: {
      type: 'tween',
      ease: [0.23, 1, 0.32, 1],
      duration: 1.75,
    },
  },
}

const Menu = ({}) => {
  const data = useStaticQuery(linksQuery)

  return (
    <>
      {data.prismicNavigation.data.main_menu.map(
        (item: { menu_link: { document: { uid: string; type: string } }; menu_label: string }, i: number) => (
          <m.li key={item.menu_label + i} variants={itemVariants}>
            <MenuLink document={item.menu_link.document} label={item.menu_label} />
          </m.li>
        ),
      )}
    </>
  )
}

export default memo(Menu)
