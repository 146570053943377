import { useEffect } from "react";
import { useWindowSize } from "@react-hook/window-size";

export default function useCalculatedVH() {
  const [width, height] = useWindowSize({ wait: 0 });

  useEffect(() => {
    const vh = height * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [width, height]);
}
