import { useEffect } from 'react'
import create from 'zustand'
import { useWindowSize } from '@react-hook/window-size'

import { store } from './store'
import useCalculatedVH from '../lib/useCalculatedVH'

export const useStore = create(store)

// const updateBg = (to: string) =>
//   // @ts-ignore
//   (document.querySelector('#bg').style.backgroundColor = `var(--color-bg-${store.getState().colorMap[to] || 'blue'})`)

export const UISideEffects = () => {
  const [width] = useWindowSize({ wait: 0 })
  const isInitialLoad = useStore(s => s.isInitialLoad)
  const scrollbar = useStore(s => s.scrollbar)
  const shouldBlockScroll = useStore(s => s.shouldBlockScroll)
  // const route = useStore(s => s.route)
  const isPageTransitioning = useStore(s => s.isPageTransitioning)
  const customHeader = useStore(s => s.customHeader)
  const isTouch = useStore(s => s.isTouch)

  useCalculatedVH()

  useEffect(() => {
    // const isTouch = !!('ontouchstart' in window || navigator.maxTouchPoints) && width <= 1024
    const isTouch =
      /mobi|android|tablet|ipad|iphone/.test(navigator.userAgent.toLowerCase()) ||
      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    document.documentElement.classList.toggle('is-touch', isTouch)
    store.setState({ isTouch })
  }, [width])

  useEffect(() => {
    if (!scrollbar) return
    shouldBlockScroll ? scrollbar.stop() : scrollbar.resume()
  }, [shouldBlockScroll, isPageTransitioning, scrollbar])

  useEffect(() => {
    if (isInitialLoad) return
    document.documentElement.classList.remove('is-initial-load')
  }, [isInitialLoad])

  useEffect(() => {
    document.documentElement.classList.toggle('is-page-transitioning', isPageTransitioning)
  }, [isPageTransitioning])

  useEffect(() => {
    document.documentElement.classList.toggle('is-custom-header', customHeader)
  }, [customHeader])

  // useEffect(() => {
  //   if (Object.keys(route).length === 0) return
  //   //@ts-ignore
  //   route.to && updateBg(route.to.replace(/^\/+/g, ''))
  // }, [route, isInitialLoad])

  return null
}
