// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var hidden = "Header-module--hidden--28ocP";
export var navigation = "Header-module--navigation--23_4x";
export var active = "Header-module--active--33iEW";
export var lineWrapper = "Header-module--lineWrapper--1JRVj";
export var line = "Header-module--line--3k0Xk";
export var isMenuOpen = "Header-module--isMenuOpen--3pprZ";
export var menu = "Header-module--menu--1lWjA";
export var mobile = "Header-module--mobile--2mNRL";
export var desktop = "Header-module--desktop--2cVLq";
export var menuOpener = "Header-module--menuOpener--3y3yg";
export var menuLine = "Header-module--menuLine--2Y6FW";
export var open = "Header-module--open--1ErVO";
export var close = "Header-module--close--3dGy4";
export var label = "Header-module--label--11JTY";
export var text = "Header-module--text--1pU2v";
export var char = "Header-module--char--2BUvo";
export var main = "Header-module--main--2TmiQ";
export var aux = "Header-module--aux--3oGwW";
export var link = "Header-module--link--3oKCf";