import React from 'react'
import queryString from 'query-string'
import { AnimatePresence, LazyMotion, domAnimation } from 'framer-motion'

import 'wipe.css'
import 'styles/styles.scss'

import { supportsWebp } from 'lib/supportsWebp'

import { setState } from 'store/store'
import { UISideEffects } from 'store/useStore'

import Header from 'components/ui/Header'

export const onRouteUpdate = ({ location, prevLocation }) => {
  // from-to routes
  const route = {
    from: prevLocation ? prevLocation.pathname : null,
    to: location.pathname,
  }
  route.from !== route.to && setState({ route })

  // set isInitialLoad to false after first page transition
  prevLocation !== null && prevLocation.pathname !== location.pathname && setState({ isInitialLoad: false })
}

// prevent default scroll update on route change start
export const shouldUpdateScroll = e => {
  const currentPosition = e.prevRouterProps ? e.getSavedScrollPosition(e.prevRouterProps.location) : [0, 0]
  window.scrollTo(...currentPosition)
  return false
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <LazyMotion features={domAnimation}>
        <Header />
        <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
      </LazyMotion>
    </>
  )
}

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <UISideEffects />
      {element}
    </>
  )
}

// After ReactDOM.render has executed
export const onInitialClientRender = () => {
  console.log('%c Design by: Max Niblock https://clockstrikestwelve.com/', 'background: #222; color: #fefefe')
  console.log('%c Dev and motion by: Stefan Vitasović https://stefan.lynxdev.io/', 'background: #222; color: #fefefe')

  window.history.scrollRestoration = 'manual'
  
  // show FPS counter?
  const qs = queryString.parse(window.location.search)
  if (typeof qs.fps !== 'undefined') {
    const script = document.createElement('script')
    script.onload = function () {
      // eslint-disable-next-line no-undef
      const stats = new Stats()
      document.body.appendChild(stats.dom)
      window.requestAnimationFrame(function loop() {
        stats.update()
        window.requestAnimationFrame(loop)
      })
    }
    script.src = '//mrdoob.github.io/stats.js/build/stats.min.js'
    document.head.appendChild(script)
  }
}

export const onClientEntry = () => {
  // IntersectionObserver polyfill (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`)
    console.log(`# IntersectionObserver is polyfilled!`)
  }

  // Set webp support variable
  ;(async () => {
    setState({ supportsWebp: await supportsWebp() })
  })()

  // if (process.env.NODE_ENV !== 'production') {
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render')
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: false,
  //   })
  // }
}
