// extracted by mini-css-extract-plugin
export var colorBgGrey = "#f7f7f7";
export var colorBgWhite = "#fff";
export var colorBgBlue = "#406080";
export var colorSkyBlue = "#406080";
export var colorDarkBlue = "#203040";
export var colorIce = "#dae0e6";
export var colorGranite = "#b1bcc4";
export var breakpointMedium = "768px";
export var breakpointDesktop = "1024px";
export var breakpointLarge = "1440px";
export var title = "Text-module--title--3z0vS";
export var heading = "Text-module--heading--3wlbe";
export var subheading = "Text-module--subheading--2-Sk8";
export var paragraph = "Text-module--paragraph--1CEq3";
export var caption = "Text-module--caption--1WOeR";
export var menuText = "Text-module--menuText--lpEQR";