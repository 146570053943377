import create from "zustand/vanilla";

// Vanilla (Main) Store
export const store = create(() => ({
  route: {},
  isInitialLoad: process.env.NODE_ENV !== 'development',
  hideLoader: false,
  animateHeader: false,
  customHeader: false,
  isPageTransitioning: false,
  isTouch: false,
  shouldBlockScroll: false,
  scrollbar: null,
  shouldCloseMenu: false,
  isBottom: false,
  supportsWebp: true,
  colorMap: {
    'mallory-and-irvine/': 'grey',
    'references/': 'grey',
  }
}));

export const getState = store.getState;
export const setState = store.setState;
export const subscribe = store.subscribe;
