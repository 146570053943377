import React from 'react'
import classNames from 'classnames/bind'
import { As, PropsWithAs } from 'reakit-utils/types'

import * as s from './Text.module.scss'

const cn = classNames.bind(s)

const SCALES = {
  title: 'title',
  heading: 'heading',
  subheading: 'subheading',
  paragraph: 'paragraph',
  caption: 'caption',
  menuText: 'menuText',
}

type BaseProps = {
  scale?: keyof typeof SCALES
  children?: React.ReactNode
  className?: string
  style?: React.CSSProperties
}

type BaseType = {
  <Tag extends As>(props: PropsWithAs<BaseProps, Tag>): JSX.Element
}

const Base: BaseType = ({ as: Component = 'p', scale = 'paragraph', children, className, ...props }) => (
  <Component {...props} className={cn(SCALES[scale], className)}>
    {children}
  </Component>
)

export const Title: BaseType = props => <Base as='h1' scale='title' {...props} />
export const Heading: BaseType = props => <Base as='h2' scale='heading' {...props} />
export const Subheading: BaseType = props => <Base as='h3' scale='subheading' {...props} />
export const Paragraph: BaseType = props => <Base as='p' scale='paragraph' {...props} />
export const Caption: BaseType = props => <Base as='p' scale='caption' {...props} />
export const MenuText: BaseType = props => <Base as='span' scale='menuText' {...props} />