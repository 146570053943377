import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames/bind'

import { useStore } from 'store/useStore'

import * as s from './Header.module.scss'
const cn = classNames.bind(s)

const openerVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      type: 'tween',
      duration: 0.5,
      delay: 2,
    },
  },
}

type MenuOpenerTypes = {
  onClick: () => void
  animateIn: boolean
}

const MenuOpener = ({ onClick, animateIn }: MenuOpenerTypes) => {
  const isInitialLoad = useStore(s => s.isInitialLoad)
  return (
    <motion.span
      className={cn('menuOpener')}
      onClick={onClick}
      variants={openerVariants}
      initial={isInitialLoad && 'hidden'}
      animate={isInitialLoad && animateIn && 'show'}
    >
      <span className={cn('open')}>
        <span className={cn('menuLine')} />
        <span className={cn('menuLine')} />
      </span>
      <span className={cn('close')}>
        <span className={cn('menuLine')} />
        <span className={cn('menuLine')} />
      </span>
    </motion.span>
  )
}

export default MenuOpener
