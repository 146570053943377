import React, { useMemo } from 'react'
import classNames from 'classnames/bind'

type LabelTypes = {
  label: string
}

import * as s from './Header.module.scss'
const cn = classNames.bind(s)

const Label = ({ label }: LabelTypes) => {
  const wrapped = useMemo(() => label.split(''), [label])

  return (
    <span className={cn('label')}>
      <span className={cn('main')}>
        {wrapped.map((char, i) => (
          <span key={char + i + 'main'} className={cn('char')}>
            {char !== ' ' ? char : '\u00A0'}
          </span>
        ))}
      </span>
      <span className={cn('aux')}>
        {wrapped.map((char, i) => (
          <span key={char + i + 'aux'} className={cn('char')}>
            {char !== ' ' ? char : '\u00A0'}
          </span>
        ))}
      </span>
    </span>
  )
}

export default Label
