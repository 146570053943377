import React, { useState, useEffect, useRef, memo, useCallback, useMemo } from 'react'
import classNames from 'classnames/bind'
import { m, useViewportScroll } from 'framer-motion'
import ScrollLock from 'react-scrolllock'
import { useWindowSize } from '@react-hook/window-size'

import { store } from 'store/store'
import { useStore } from 'store/useStore'

import MenuOpener from './MenuOpener'
import Menu from './Menu'

import * as s from './Header.module.scss'
const cn = classNames.bind(s)

const lineVariants = {
  hidden: {
    scaleX: 0,
  },
  show: {
    scaleX: 1,
    transition: {
      type: 'tween',
      ease: [0.77, 0, 0.175, 1],
      duration: 2.5,
      delay: 1,
    },
  },
}

const menuWrapperVariants = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      type: 'tween',
      staggerChildren: 0.6,
      delayChildren: 1.25,
    },
  },
}

const Header = () => {
  const animateHeader = useStore(s => s.animateHeader)
  const isInitialLoad = useStore(s => s.isInitialLoad)
  const shouldCloseMenu = useStore(s => s.shouldCloseMenu)

  const [animateIn, setAnimateIn] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const header = useRef<HTMLDivElement>(null)

  const scrollbar = useStore(s => s.scrollbar)
  const isPageTransitioning = useStore(s => s.isPageTransitioning)
  const isBottom = useStore(s => s.isBottom)
  const isTouch = useStore(s => s.isTouch)
  const visible = useRef('data-header-visible').current

  const [width] = useWindowSize()
  const { scrollY } = useViewportScroll()
  const yBuffer = useRef(0)

  useEffect(() => {
    if (animateHeader) setAnimateIn(true)
    else if (isInitialLoad && window.location.pathname !== '/') store.setState({ animateHeader: true })
  }, [animateHeader, isInitialLoad])

  useEffect(() => {
    shouldCloseMenu && setIsMenuOpen(false)
  }, [shouldCloseMenu])

  const onOpenerClick = useCallback(() => {
    setIsMenuOpen(s => !s)
  }, [])

  useEffect(() => {
    if (!header.current) return
    header.current.classList.toggle(cn('hidden'), isBottom && isPageTransitioning)
  }, [isBottom, header, isPageTransitioning])

  useEffect(() => {
    if (!header.current || !isBottom) return
    header.current.removeAttribute(visible)
  }, [isBottom])

  useEffect(() => {
    if (!scrollbar || !header.current) return
    if (!scrollbar.state?.initialised) return
    !header.current?.getAttribute(visible) && !isBottom && header.current.setAttribute(visible, 'true')

    const updateOnScroll = ({ direction }) => {
      if (!header.current || isBottom) return
      if (direction === 'down') {
        header.current?.getAttribute(visible) && header.current.removeAttribute(visible)
      } else if (direction === 'up') {
        !header.current?.getAttribute(visible) && header.current.setAttribute(visible, 'true')
      }
    }

    scrollbar.on('scroll', updateOnScroll)
    return () => scrollbar.off('scroll', updateOnScroll)
  }, [header, scrollbar, width, isBottom])

  useEffect(() => {
    if ((scrollbar && scrollbar.state?.initialised) || !header.current || !scrollY) return
    !header.current?.getAttribute(visible) && header.current.setAttribute(visible, 'true')

    const updateOnScroll = (y: number) => {
      if (y > yBuffer.current + 2) {
        header.current?.getAttribute(visible) && header.current.removeAttribute(visible)
      } else if (y < yBuffer.current - 10 || y <= 0) {
        !header.current?.getAttribute(visible) && header.current.setAttribute(visible, 'true')
      }
      yBuffer.current = y
    }

    scrollY.onChange(updateOnScroll)
    return () => updateOnScroll
  }, [scrollY, header, scrollbar, width, isBottom])

  return (
    <header ref={header} className={cn({ isMenuOpen })}>
      <nav className={cn('navigation')}>
        <ScrollLock isActive={isMenuOpen} />
        <MenuOpener onClick={onOpenerClick} animateIn={animateIn} />
        <m.span
          className={cn('lineWrapper')}
          variants={lineVariants}
          initial={isInitialLoad && 'hidden'}
          animate={isInitialLoad && animateIn && 'show'}
        >
          <span className={cn('line')} />
        </m.span>
        <div className={cn('menu', 'desktop')}>
          <m.ul
            variants={menuWrapperVariants}
            initial={isInitialLoad && 'hidden'}
            animate={isInitialLoad && animateIn && 'show'}
          >
            <Menu />
          </m.ul>
        </div>
        <div className={cn('menu', 'mobile')}>
          <ul>
            <Menu />
          </ul>
        </div>
      </nav>
    </header>
  )
}

export default memo(Header)
